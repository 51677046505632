import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Table
} from 'react-bootstrap'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import el from 'date-fns/esm/locale/el/index.js'

interface Props {
  gateAccess: any
  gateTotalAccess: any
  sendVisits: (item: any) => void
}

export function GateFlow({ gateAccess, gateTotalAccess, sendVisits }: Props) {
console.log('[[[[[[[[[[[[')
console.log({gateAccess})
console.log({gateTotalAccess})
console.log('[[[[[[[[[[[[')
  const [gateFlow, setGateFlow] = useState<any>()

  const [gateFlowExit, setGateFlowExit] = useState(gateAccess)

  function renderEnumValue(enumValue: any) {
    switch (enumValue) {
      case 'employee':
        return <div>Funcionários</div>;
      case 'member':
        return <div>Asssociados</div>;
      case 'visitors':
        return <div>Visitantes</div>;
      case 'guest':
        return <div>Convidados</div>;
      case 'outros':
        return <div>Outros</div>;
      default:
        return null;
    }
  }

  const temp_gateFlow: any = {
    employee: {
      entrance: 0,
      exit: 0
    },
    member: {
      entrance: 0,
      exit: 0
    },
    visitors: {
      entrance: 0,
      exit: 0
    },
    guest: {
      entrance: 0,
      exit: 0
    },
    outros: {
      entrance: 0,
      exit: 0
    }
  }

  useEffect(() => {

      gateAccess.map((item: any) => {

        if (item.direction === 'exit') {
          if (item.userType === 'member') {
            temp_gateFlow['member'].exit = item.count
          }
          if (item.userType === 'employee') {
            temp_gateFlow['employee'].exit = item.count
          }
          if (item.userType === '') {
            temp_gateFlow['outros'].exit = item.count
          }
        } else
        if (item.direction === 'entrance') {
          if(item.userType === 'member'){
            temp_gateFlow['member'].entrance = item.count
          }
          if(item.userType === 'employee'){
            temp_gateFlow['employee'].entrance = item.count
          }
          if(item.userType === ''){
            temp_gateFlow['outros'].entrance = item.count
          }

        }
        // else {
        //   temp_gateFlow[item.userType].entrance = item.count
        // }
      })

      setGateFlow(temp_gateFlow)

  }, [gateAccess])

  return (
    <>
      <Card.Body className="">
        <Table responsive striped>
          {gateFlow &&
            gateFlow.member &&

            <tbody>
              <tr>
                <td className="pb-0 pt-0 align-right">Associados</td>
                <td className="pb-0 pt-0">
                  <i className="feather icon-arrow-up"></i>
                  {gateFlow.member.entrance}
                </td>
                <td className="pb-0 pt-0">
                  <i className="feather icon-arrow-down"></i>
                  {gateFlow.member.exit}
                </td>
              </tr>
              <tr>
                <td className="pb-0 pt-0 align-right">Funcionários</td>
                <td className="pb-0 pt-0">
                  <i className="feather icon-arrow-up"></i>
                  {gateFlow.employee.entrance}
                </td>
                <td className="pb-0 pt-0">
                  <i className="feather icon-arrow-down"></i>
                  {gateFlow.employee.exit}
                </td>
              </tr>
              {/* <tr>
              <td className="pb-0 pt-0 align-right">Prestadores</td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-up"></i>
                2
              </td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-down"></i>
                12
              </td>
            </tr> */}
              {/* <tr>
              <td className="pb-0 pt-0 align-right">Visitantes</td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-up"></i>
                66
              </td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-down"></i>
                16
              </td>
            </tr>
            <tr>
              <td className="pb-0 pt-0 align-right">Convidados</td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-up"></i>
                7
              </td>
              <td className="pb-0 pt-0">
                <i className="feather icon-arrow-down"></i>
                12
              </td>
            </tr> */}
              <tr>
                <td className="pb-0 pt-0 align-right">Outros</td>

                <td className="pb-0 pt-0">
                  <i className="feather icon-arrow-up"></i>
                  {gateFlow.outros.entrance}
                </td>
                <td className="pb-0 pt-0">
                  <i className="feather icon-arrow-down"></i>
                  {gateFlow.outros.exit}
                </td>
              </tr>
            </tbody>
          }
        </Table>
      </Card.Body>
      <Card.Footer>
        <div className="row align-items-center justify-content-center card-active">
          <div className="col-6">
            <h6 className="text-center m-b-10">
              <span className="text-muted m-r-5">Entraram:</span>
              {gateTotalAccess.entrance}
            </h6>
          </div>
          <div className="col-6">
            <h6 className="text-center  m-b-10">
              <span className="text-muted m-r-5">Saíram:</span>
              {gateTotalAccess.exit}
            </h6>
          </div>
        </div>
      </Card.Footer>
    </>
  )
}
