import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { ListApprovers } from '../views/components/listApprovers'
import { CreateApprover } from '../views/Auth/CreateApprover'
import { CreateVisitorType } from '../views/components/CreateVisitorType'
import { ListVisitorTypes } from '../views/components/listVisitorTypes'

function VisitorTypes() {
  const currentUser = sessionStorage.getItem('role')

  if (currentUser === 'default') {
    window.location.href = '/dashboard'
  }
  const auth = sessionStorage.getItem('auth')

  if (auth !== 'true') {
    window.location.href = '/'
  }
  const [photoState, setphotoState] = useState<string>('')

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }
  }, [])

  return (
    <>
      <Row>
        <Col md={6}>
          <CreateVisitorType />
        </Col>
        <Col md={6}>
          <ListVisitorTypes />
        </Col>
      </Row>
    </>
  )
}

export default VisitorTypes
