import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'

import { TotalUsers } from '../components/totalUsers'
import { GateFlow } from '../components/gateFlow'
import { EntranceStatus } from '../components/EntranceStatus'
import { ExitStatus } from '../components/ExitStatus'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

function Monitoring() {
  const currentUser = sessionStorage.getItem('role')

  if (currentUser === 'default') {
    window.location.href = '/dashboard'
  }


  const [entranceToday, setEntranceToday] = useState(0)
  const [exitToday, setExitToday] = useState(0)
  const [currentTotal, setCurrentTotal] = useState(0)

  const [acessLogMatarazzo, setAcessLogMatarazzo] = useState<any[]>([])
  const [acessLogPalestra, setAcessLogPalestra] = useState<any[]>([])
  const [acessLogEstacionamento, setAcessLogEstacionamento] = useState<any[]>([])
  const [acessLogConveniencia, setAcessLogConveniencia] = useState<any[]>([])

  const [acessTotalLogMatarazzo, setAcessTotalLogMatarazzo] = useState<any[]>([])
  const [acessTotalLogPalestra, setAcessTotalLogPalestra] = useState<any[]>([])
  const [acessTotalLogEstacionamento, setAcessTotalLogEstacionamento] = useState<any[]>([])
  const [acessTotalLogConveniencia, setAcessTotalLogConveniencia] = useState<any[]>([])


  const getTotalVisits = useCallback((countValue: any) => {
    return true
  }, [])

  const allGateStatus = () => {
    fetch(`${BASE.api_report.base_url}${BASE.api_report.access_total}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({})
    })
      .then((resg) => resg.json())
      .then((response) => {

        if (!response) {
          return false
        }

        const matarazzo: any = [], estacionamento: any = [], palestra: any = [], conveniencia: any = []
        response.data.summary.map((item: any) => {
          if (item.deviceGroup === 'matarazzo') {
            matarazzo.push(item)
          }
         else if (item.deviceGroup === 'estacionamento') {
            estacionamento.push(item)
          } else if (item.deviceGroup === 'palestra') {
            palestra.push(item)
          } else if (item.deviceGroup === 'convivencia') {
            conveniencia.push(item)
          }
        })
        setAcessLogMatarazzo(matarazzo)
        setAcessLogEstacionamento(estacionamento)
        setAcessLogPalestra(palestra)
        setAcessLogConveniencia(conveniencia)

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const totalStatus = () => {
    fetch(`${BASE.api_report.base_url}${BASE.api_report.access_total}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({})
    })
      .then((resg) => resg.json())
      .then((response) => {
        if (!response) {
          return false
        }

        const matarazzoTotals: any = { entrance: 0, exit: 0 }, estacionamento: any = { entrance: 0, exit: 0 }, palestra: any = { entrance: 0, exit: 0 }, conveniencia: any = { entrance: 0, exit: 0 }

          const matarazzoArray: any = [], estacionamentoArray: any = [], palestraArray: any = [], convenienciaArray: any = []
          response.data.summary.map((item: any) => {
            if (item.deviceGroup === 'matarazzo') {
              matarazzoArray.push(item)
            }
            else if (item.deviceGroup === 'estacionamento') {
              estacionamentoArray.push(item)
            } else if (item.deviceGroup === 'palestra') {
              palestraArray.push(item)
            } else if (item.deviceGroup === 'convivencia') {
              convenienciaArray.push(item)
            }
          })

        palestra.entrance = palestraArray.map( (mat: any) => {
          if(mat.direction === 'entrance'){
            return parseInt(mat.count)
          } else { return 0}
        }).reduce((total: any, current: any)=> {
          return total+current
        })
        palestra.exit = palestraArray.map( (mat: any) => {
          if(mat.direction === 'exit'){
            return parseInt(mat.count)
          } else { return 0}
        }).reduce((total: any, current: any)=> {
          return total+current
        })
        const totalMatarazzo: any = { entrance: 0, exit: 0 }
        totalMatarazzo.entrance = matarazzoArray.map( (mat: any) => {
          if(mat.direction === 'entrance'){
            return parseInt(mat.count)
          } else { return 0}
        }).reduce((total: any, current: any)=> {
          return total+current
        })
        totalMatarazzo.exit = matarazzoArray.map( (mat: any) => {
          if(mat.direction === 'exit'){
            return parseInt(mat.count)
          } else { return 0}
        }).reduce((total: any, current: any)=> {
          return total+current
        })

        estacionamento.entrance = estacionamentoArray.map( (item: any) => {
          if(item.direction === 'entrance'){
            return parseInt(item.count)
          } else { return 0}
        }).reduce((total: any, current: any)=> {
          return total+current
        })
        estacionamento.exit = estacionamentoArray.map( (item: any) => {
          if(item.direction === 'exit'){
            return parseInt(item.count)
          } else { return 0}
        }).reduce((total: any, current: any)=> {
          return total+current
        })
        conveniencia.entrance = convenienciaArray.map( (item: any) => {
          if(item.direction === 'entrance'){
            return parseInt(item.count)
          } else { return 0}
        }).reduce((total: any, current: any)=> {
          return total+current
        })
        conveniencia.exit = convenienciaArray.map( (item: any) => {
          if(item.direction === 'exit'){
            return parseInt(item.count)
          } else { return 0}
        }).reduce((total: any, current: any)=> {
          return total+current
        })
          const total = parseInt(response.data.totalEntrances) - parseInt(response.data.totalExits)

        setCurrentTotal(total > 0 ? total: 0)
        setAcessTotalLogMatarazzo(totalMatarazzo)
        setAcessTotalLogEstacionamento(estacionamento)
        setAcessTotalLogPalestra(palestra)
        setAcessTotalLogConveniencia(conveniencia)
         setExitToday(response.data.totalExits)
        setEntranceToday(response.data.totalEntrances)


      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    allGateStatus()
    totalStatus()
    setInterval(allGateStatus, 100000)
    setInterval(totalStatus, 100000)
  }, [])

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Matarazzo</Card.Title>
            </Card.Header>
            <Col sm={12}>
              <GateFlow
                gateTotalAccess={acessTotalLogMatarazzo}
                sendVisits={getTotalVisits}
                gateAccess={acessLogMatarazzo}
              />
            </Col>
          </Card>

        </Col>
        <Col sm={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Palestra</Card.Title>
            </Card.Header>
            <Col sm={12}>
              <GateFlow
                gateTotalAccess={acessTotalLogPalestra}
                sendVisits={getTotalVisits}
                gateAccess={acessLogPalestra}
              />
            </Col>
          </Card>

        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Estacionamento</Card.Title>
            </Card.Header>
            <Col sm={12}>
              <GateFlow
                gateTotalAccess={acessTotalLogEstacionamento}
                sendVisits={getTotalVisits}
                gateAccess={acessLogEstacionamento}
              />
            </Col>
          </Card>

        </Col>
        <Col sm={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>Conveniência</Card.Title>
            </Card.Header>
            <Col sm={12}>
              <GateFlow
                gateTotalAccess={acessTotalLogConveniencia}
                sendVisits={getTotalVisits}
                gateAccess={acessLogConveniencia}
              />
            </Col>
          </Card>

        </Col>
      </Row>
      <Row>
        <Col sm={6} md={3}>
          <EntranceStatus totalData={entranceToday} />
        </Col>
        <Col sm={6} md={3}>
          <ExitStatus totalData={exitToday} />
        </Col>
        <Col sm={6} md={3}>
          <TotalUsers totalData={currentTotal} />
        </Col>
        {/* <Col sm={6} md={3}>
          <BiometryTotal totalData={16634} />
        </Col> */}
      </Row>
    </>
  )
}

export default Monitoring
