import React, { useEffect, useState } from 'react'

import './LoadingHandler.sass'

const LoadingHandler = () => {
  return (
    <div className='local-loader'>
      <i
        className="fa fa-spinner fa-spin text-c-green f-10 m-r-15"
        title="oiee"
      />
    </div>
  )
}

export default LoadingHandler
