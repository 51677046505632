import React from 'react'
import {
    Row,
    Col,
    Card,
} from 'react-bootstrap'

interface Props {
    devices: any,
    totalReference: any
}

export function GateCount({ devices, totalReference }: Props) {

    return (
      <Row>
      {devices &&
        devices
          .sort((a: any, b: any) => {
            const lastOctetA = Number(a.ip.split('-')[1].slice(0,2));
            const lastOctetB = Number(b.ip.split('-')[1].slice(0,2));
            return  lastOctetA - lastOctetB; // Ascending order
          })
          .map((terminal: any, index: any) => {
            return (
              <Col key={index} xl={3}>
                <Card
                  className={`game-label ${
                    Number(totalReference) !== Number(terminal.count)
                      ? terminal.count === 'terminalInactive' || terminal.count === 'offline'
                        ? 'danger'
                        : 'warning'
                      : 'default'
                  }`}
                >
                  <h6 className="gate-name">{terminal.ip.split('.').pop()}</h6>
                  {terminal.count}
                </Card>
              </Col>
            );
          })}
    </Row>
    )
}
