import React, { useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  FloatingLabel,
  Dropdown,
  Table,
  Alert
} from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import './visitorCreate.sass'

registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const strongPassword = (password: any) => {
  return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
    password
  )
}

export function UserCreate() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const [startDate, setStartDate] = useState(new Date())

  const [hasToken, setHasToken] = useState('')

  const [disableSign, setDisableSign] = useState(false)

  const [newUser, setNewUser] = useState({
    login: '',
    password: '',
    set: false
  })

  const role = sessionStorage.getItem('role')


  function string_to_slug(str: any) {
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    const to = 'aaaaeeeeiiiioooouuuunc------'
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '_') // collapse whitespace and replace by -
      .replace(/-+/g, '_') // collapse dashes

    return str
  }

  const onSubmit = (data: any) => {
    setDisableSign(true)


    const username = string_to_slug(data.name)

    fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        name: data.name.replace(/^\s+|\s+$/g, ''),
        email: data.email?.replace(/^\s+|\s+$/g, '') || 'a@a.com',
        login: data.email?.replace(/^\s+|\s+$/g, '') || 'a@a.com',
        password: `AM${Math.random().toString(36).slice(-8)}!`,
        role: data.type || 'default',
        createdBy: data.reroll || 'default'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response)
        setNewUser({
          login: response.login,
          password: response.password,
          set: true
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const resetForm = () => {
    setNewUser({
      login: '',
      password: '',
      set: false
    })
    setHasToken('')
    setDisableSign(false)
    reset()
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Cadastro de usuários</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-3">
                <Col>
                  <Form.Label>Tipo de acesso</Form.Label>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <select
                      className="form-control"
                      {...register('type', { required: true })}
                    >
                      {(role === 'bedimin') ? (
                        <>
                          <option value="default">Padrão</option>
                          <option value="monitoring">Monitoramento</option>
                          <option value="admin">Administrador</option>
                          <option value="generalEditor">Editor de cadastro: Geral</option>
                          <option value="supervisingEditor">Editor de cadastro: Supervisão</option>
                        </>
                      ) : (
                        <>
                          <option value="default">SAC: Perfil de Consulta</option>
                          <option value="supervisingEditor">Editor de cadastro: Supervisão</option>
                          <option value="gameops">Jogo: Contingência</option>
                          <option value="admin">Administração</option>
                        </>
                      )}
                    </select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    placeholder="Nome Completo"
                    {...register('name', { required: true })}
                  />
                  {errors.name && (
                    <span className="label-error">
                      Você precisa informar um Nome.
                    </span>
                  )}
                </Col>

                {role === 'admin' ? (<Col>
                  <Form.Label>E-mail (opcional)</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-mail"
                    {...register('email')}
                  />
                  {errors.email && (
                    <span className="label-error">
                      Você precisa informar um E-mail válido
                    </span>
                  )}
                </Col>) : (<Col>
                  <Form.Label>Portão</Form.Label>
                  <select
                    className="form-control"
                    {...register('reroll', { required: true })}
                  >
                    <>
                      <option value="1">A Central Oeste</option>
                      <option value="2">A Superior Sul</option>
                      <option value="3">A Gol Sul</option>
                      <option value="6">B Superior Leste</option>
                      <option value="7">B Gol Norte</option>
                      <option value="8">C Central Leste</option>
                      <option value="5">D Visitantes</option>
                    </>
                    {/* <option value="supervisor">Supervisor</option> */}
                  </select>
                </Col>)}
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Button
                      disabled={disableSign}
                      type="submit"
                      className="mb-0 w-100"
                      onClick={() => clearErrors()}
                    >
                      Cadastrar Usuário
                    </Button>
                  </Form.Group>
                </Col>
                <Col>
                  {disableSign && (
                    <Form.Group>
                      <Button
                        className="mb-0 btn-success w-100"
                        onClick={() => resetForm()}
                      >
                        Cadastrar Outro
                      </Button>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
            </Form>
          </Col>
        </Row>

        {newUser.set && (
          <>
            <hr />
            <Row>
              <Col className="qr-wrapper">
                <h3>Usuário cadastrado com sucesso!</h3>
                <p>
                  Login: <strong>{newUser.login}</strong>
                  <br />
                  Senha provisória: <strong>{newUser.password}</strong>
                </p>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  )
}
