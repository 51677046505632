import React from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Table
} from 'react-bootstrap'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

interface Props {
  totalData: any
}

export function TotalUsers({ totalData }: Props) {
  return (
    <Card className="card-social">
      <Card.Body className="border-bottom">
        <div className="row align-items-center justify-content-center">
          <div className="col text-center">
            <h3>
              {totalData} <span className="text-muted">Agora no Clube</span>
            </h3>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
