export const firebaseConfig =  {
  apiKey: "AIzaSyBpVxHFxhvgpvh1j50PzpQ704e2o1Gyib4",
  authDomain: "bepass-platform.firebaseapp.com",
  databaseURL: "https://bepass-platform-default-rtdb.firebaseio.com",
  projectId: "bepass-platform",
  storageBucket: "bepass-platform.appspot.com",
  messagingSenderId: "563791290358",
  appId: "1:563791290358:web:fee554b4ed8f5dd2caf1d7",
  measurementId: "G-23LRVL1WCB"
};

