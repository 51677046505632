import React, { useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Alert,
  Table
} from 'react-bootstrap'
import './findUserFull.sass'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'

import { useForm } from 'react-hook-form'

export function ListUsers() {
  const [userList, setUserList] = useState([])

  const [userSearch, setUserSearch] = useState({
    identifier: '',
    type: 'externalId'
  })

  const [userNotFound, setUserNorFound] = useState(false)
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues
  } = useForm()

  const onSubmit = (data: any) => {
    setUserNorFound(false)

    fetch(`${BASE.api.base_url}${BASE.api.access_search}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        name: data.identifier
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        if (response.length < 1 || response.success === false) {
          setUserNorFound(true)
          setTimeout(() => {
            setUserNorFound(false)
          }, 4000)
        }else{
          setUserList(response)
        }
      })
      .catch((error) => {
        setUserNorFound(true)
        setTimeout(() => {
          setUserNorFound(false)
        }, 4000)
      })
  }

  return (
    <Card className="">
      <Card.Header>
        <Card.Title as="h5" className="search-header">
          Pesquisar Usuário
        </Card.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup className="mb-3">
            <input
              placeholder="Nome completo"
              className="form-control"
              {...register('identifier', { required: true })}
            />
            <input
              className="btn btn-primary"
              type="submit"
              value="Pesquisar"
              onClick={() => clearErrors()}
            />
          </InputGroup>
        </form>
      </Card.Header>
      <Card.Body className="px-0 py-2">
        {userNotFound && (
          <Alert variant="warning">Usuário não encontrado</Alert>
        )}
        <Table responsive hover>
          <tbody>
            {userList &&
              userList.map((user: any) => (
                <tr key={user.hikiId} className="unread">
                  <td colSpan={2}>
                    <p className="mb-0"><b>Nome:</b> {user.name}</p>
                    <p className="m-0"><b>Tipo de acesso:</b> {user.role}</p>
                  </td>
                  <td colSpan={2}>
                    <h6 className="mb-0">Login</h6>
                    <p>{user.login}</p>
                  </td>
                  <td colSpan={2}>
                    <p className="text-muted">
                      <a
                        href={`/user/edit/${user.id}`}
                        className="text-decoration-none"
                      >
                        Editar
                      </a>
                    </p>
                  </td>
                  {/* <td colSpan={2}>
                    <p className="text-muted">
                      <a className="text-decoration-none">Excluir</a>
                    </p>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}
